import React, { useState, useEffect, useRef } from "react";
import styles from "./ambiance.module.css";

const Ambiance = ({ currentHour, noTransition }) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [mouseSpeed, setMouseSpeed] = useState(0);
  const [celestialObjects, setCelestialObjects] = useState([]);
  const lastMousePosition = useRef({ x: 0, y: 0 });
  const requestRef = useRef();
  const previousTimeRef = useRef();

  const isNightTime = currentHour >= 21 || currentHour < 5;
  const isDawnDusk =
    (currentHour >= 5 && currentHour < 8) ||
    (currentHour >= 18 && currentHour < 21);

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const animateCelestialObject = (time) => {
    if (previousTimeRef.current !== undefined) {
      const deltaTime = time - previousTimeRef.current;
      const dx = mousePosition.x - lastMousePosition.current.x;
      const dy = mousePosition.y - lastMousePosition.current.y;
      const distance = Math.sqrt(dx * dx + dy * dy);
      const speed = distance / deltaTime;
      setMouseSpeed(speed);
      lastMousePosition.current = { ...mousePosition };

      // Create a new celestial object
      if (speed > 0.1 && Math.random() > (isNightTime ? 0.1 : 0.3)) {
        const baseSize = isNightTime
          ? Math.random() * 6 + 1 // Reduced max size for stars
          : Math.random() * 100 + 50;
        const objectSize = Math.max(isNightTime ? 1 : 50, baseSize - speed * 2);
        let opacity = Math.min(0.8, speed * (isNightTime ? 0.2 : 0.04)); // Increased max opacity for stars
        if (isDawnDusk && !isNightTime) {
          opacity /= 2; // Make clouds twice as transparent during dawn/dusk
        }
        const lingerTime = Math.random() * 2000 + 1000;
        const aspectRatio = isNightTime ? 1 : 0.5 + Math.random() * 0.5;
        const blur = isNightTime
          ? Math.random() > 0.5
            ? "1px"
            : "0px"
          : Math.random() * 12 + 8 + "px"; // Increased blur for daytime clouds
        const angle = isNightTime ? 0 : Math.random() * 30 - 15;
        const shape = isNightTime
          ? Math.random() > 0.5
            ? 0
            : 1 // 0 for dot, 1 for star
          : Math.floor(Math.random() * 4);
        setCelestialObjects((prevObjects) => [
          ...prevObjects,
          {
            id: Date.now(),
            x: mousePosition.x + (Math.random() - 0.5) * 100,
            y: mousePosition.y + (Math.random() - 0.5) * 100,
            width: objectSize,
            height: objectSize * aspectRatio,
            opacity,
            lingerTime,
            blur,
            angle,
            shape,
          },
        ]);
      }
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animateCelestialObject);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animateCelestialObject);
    return () => cancelAnimationFrame(requestRef.current);
  }, [mousePosition]);

  useEffect(() => {
    const fadeOutObjects = setInterval(() => {
      setCelestialObjects((prevObjects) =>
        prevObjects
          .map((object) => ({
            ...object,
            opacity: object.opacity > 0 ? object.opacity - 0.01 : 0,
          }))
          .filter((object) => object.opacity > 0)
      );
    }, 50);

    return () => clearInterval(fadeOutObjects);
  }, []);

  const getCelestialObjectShape = (shape, width, height) => {
    if (isNightTime) {
      // Star shapes
      switch (shape) {
        case 0: // Bright white dot
          return `0 0 ${width / 2}px ${height / 2}px #fff`;
        case 1: // 5-pointed star
          const starPoints = [
            `${width / 2} 0`,
            `${width * 0.6} ${height * 0.4}`,
            `${width} ${height * 0.4}`,
            `${width * 0.7} ${height * 0.6}`,
            `${width * 0.8} ${height}`,
            `${width / 2} ${height * 0.75}`,
            `${width * 0.2} ${height}`,
            `${width * 0.3} ${height * 0.6}`,
            `0 ${height * 0.4}`,
            `${width * 0.4} ${height * 0.4}`,
          ].join(",");
          return `polygon(${starPoints})`;
        default:
          return `0 0 ${width / 2}px ${height / 2}px #fff`;
      }
    } else {
      // Cloud shapes (more blurry)
      const blurRadius = Math.random() * 10 + 10; // Increased blur radius for clouds
      switch (shape) {
        case 0:
          return `
            ${width * 0.5}px ${height * 0.2}px ${blurRadius}px 0 white,
            ${width * 0.3}px ${height * -0.1}px ${blurRadius}px 0 white,
            ${width * -0.1}px ${height * 0.1}px ${blurRadius}px 0 white,
            ${width * -0.3}px ${height * -0.2}px ${blurRadius}px 0 white
          `;
        case 1:
          return `
            ${width * 0.6}px ${height * 0.3}px ${blurRadius}px 0 white,
            ${width * 0.2}px ${height * -0.2}px ${blurRadius}px 0 white,
            ${width * -0.2}px ${height * 0.2}px ${blurRadius}px 0 white,
            ${width * -0.4}px ${height * -0.1}px ${blurRadius}px 0 white
          `;
        case 2:
          return `
            ${width * 0.4}px ${height * 0.1}px ${blurRadius}px 0 white,
            ${width * 0.1}px ${height * -0.3}px ${blurRadius}px 0 white,
            ${width * -0.3}px ${height * 0.3}px ${blurRadius}px 0 white,
            ${width * -0.5}px ${height * -0.2}px ${blurRadius}px 0 white
          `;
        case 3:
          return `
            ${width * 0.5}px ${height * 0.4}px ${blurRadius}px 0 white,
            ${width * 0.4}px ${height * -0.2}px ${blurRadius}px 0 white,
            ${width * -0.2}px ${height * 0.3}px ${blurRadius}px 0 white,
            ${width * -0.4}px ${height * -0.3}px ${blurRadius}px 0 white
          `;
        default:
          return "";
      }
    }
  };

  return (
    <div
      className={`${styles.wrap} ${noTransition ? styles.noTransition : ""}`}
    >
      <div className={styles.flair}>
        {celestialObjects.map((object) => (
          <div
            key={object.id}
            className={styles.celestialObject}
            style={{
              left: `${object.x}px`,
              top: `${object.y}px`,
              width: `${object.width}px`,
              height: `${object.height}px`,
              opacity: object.opacity,
              backgroundColor: isNightTime
                ? object.shape === 0
                  ? "white"
                  : "transparent"
                : "white",
              position: "absolute",
              zIndex: 1000,
              transition: `opacity ${object.lingerTime}ms linear`,
              borderRadius: isNightTime
                ? object.shape === 0
                  ? "50%"
                  : "0%"
                : "50%",
              boxShadow: isNightTime
                ? object.shape === 0
                  ? getCelestialObjectShape(
                      object.shape,
                      object.width,
                      object.height
                    )
                  : "none"
                : getCelestialObjectShape(
                    object.shape,
                    object.width,
                    object.height
                  ),
              filter: `blur(${object.blur})`,
              transform: `rotate(${object.angle}deg)`,
              clipPath:
                isNightTime && object.shape === 1
                  ? getCelestialObjectShape(
                      object.shape,
                      object.width,
                      object.height
                    )
                  : "none",
              mixBlendMode: isNightTime ? "normal" : "overlay",
            }}
          />
        ))}
      </div>

      <div className={styles.sky}>
        <div className={styles.heading}>Get in Touch:</div>
        <ul>
          <li>
            <a
              href="mailto:hello@jpolicinski.com?subject=Let's%20Connect!"
              aria-label="Email"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                <polyline points="22,6 12,13 2,6" />
              </svg>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/jpolicinski"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="LinkedIn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />
                <rect x="2" y="9" width="4" height="12" />
                <circle cx="4" cy="4" r="2" />
              </svg>
            </a>
          </li>
          {/* <li>
            <a
              href="https://github.com/jfalotico"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="GitHub"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22" />
              </svg>
            </a>
          </li> */}
          {/* 
          Hide Codepen until I can clean it up
          <li>
            <a
              href="https://codepen.io/jfalotico"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Codepen"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polygon points="12 2 22 8.5 22 15.5 12 22 2 15.5 2 8.5 12 2"></polygon>
                <line x1="12" y1="22" x2="12" y2="15.5"></line>
                <polyline points="22 8.5 12 15.5 2 8.5"></polyline>
                <polyline points="2 15.5 12 8.5 22 15.5"></polyline>
                <line x1="12" y1="2" x2="12" y2="8.5"></line>
              </svg>
            </a>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default Ambiance;
