import React, { useState, useEffect, useCallback } from "react";
import AboutMe from "../about/about";
import styles from "./sun.module.css";
import Ambiance from "../ambiance/ambiance";

const SunPositionElement = () => {
  const [sliderValue, setSliderValue] = useState(getCurrentTimeInSeconds());
  const [isRealTimeActive, setIsRealTimeActive] = useState(true);
  const [skipTransition, setSkipTransition] = useState(false);
  const [noTransition, setNoTransition] = useState(false);
  const [prevHour, setPrevHour] = useState(null);

  // Helper function to get the current time in seconds
  function getCurrentTimeInSeconds() {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    return hours * 3600 + minutes * 60 + seconds;
  }

  useEffect(() => {
    const updateBasedOnTime = () => {
      if (isRealTimeActive) {
        const currentTimeInSeconds = getCurrentTimeInSeconds();
        setSliderValue(currentTimeInSeconds); // Sync slider with real time
      }
    };

    const interval = setInterval(updateBasedOnTime, 1000); // Update every second
    return () => clearInterval(interval);
  }, [isRealTimeActive]);

  // Updated function to format time in AM/PM
  const formatTimeFromSeconds = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const ampm = hours >= 12 ? "PM" : "AM";
    let formattedHours = hours % 12 || 12; // Convert 0 to 12 for midnight

    // Special case for 24:00 (midnight)
    if (hours === 24) {
      formattedHours = 12;
      return "12:00 AM";
    }

    return `${formattedHours}:${minutes.toString().padStart(2, "0")} ${ampm}`;
  };

  // Function to calculate the degree of rotation based on time
  const calculateRotation = (timeInSeconds) => {
    const dayProgress = timeInSeconds / 86400; // 86400 seconds in a day
    return dayProgress * 360 - 90; // Rotate by -90 degrees to make midnight at the bottom
  };

  // Function to calculate the current hour from slider value
  const getCurrentHour = (timeInSeconds) => {
    return Math.floor((timeInSeconds / 86400) * 24); // Convert seconds to hours, 0 to 24 range
  };

  // Function to revert to the current time
  const revertToCurrentTime = () => {
    const currentTime = getCurrentTimeInSeconds();
    const startTime = sliderValue;
    const duration = 1000; // Animation duration in milliseconds
    const startTimestamp = performance.now();

    const animate = (timestamp) => {
      const elapsed = timestamp - startTimestamp;
      const progress = Math.min(elapsed / duration, 1);

      // Use easeInOutCubic for smooth acceleration and deceleration
      const easeProgress =
        progress < 0.5
          ? 4 * progress * progress * progress
          : 1 - Math.pow(-2 * progress + 2, 3) / 2;

      const newValue = Math.round(
        startTime + (currentTime - startTime) * easeProgress
      );
      setSliderValue(newValue);

      if (progress < 1) {
        requestAnimationFrame(animate);
      } else {
        setIsRealTimeActive(true); // Re-enable real-time updates after animation
      }
    };

    requestAnimationFrame(animate);
  };

  // Calculate current hour from sliderValue
  const currentHour = getCurrentHour(sliderValue);

  useEffect(() => {
    if (prevHour !== null) {
      if (
        (prevHour === 23 && currentHour === 0) ||
        (prevHour === 0 && currentHour === 23) ||
        (prevHour === 23 && currentHour === 24) ||
        (prevHour === 24 && currentHour === 0)
      ) {
        setNoTransition(true);
        setTimeout(() => setNoTransition(false), 50);
      }
    }
    setPrevHour(currentHour);
  }, [currentHour, prevHour]);

  // New function to handle keyboard events
  const handleKeyDown = useCallback((event) => {
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      const direction = event.key === "ArrowLeft" ? -1 : 1;

      setSliderValue((prevValue) => {
        // Round to the nearest hour
        let nearestHour = Math.round(prevValue / 3600);

        // Move to the next or previous hour, allowing 0 and 24
        nearestHour = (nearestHour + direction + 25) % 25;

        // Check if transitioning between 24 and 0 or 0 and 24
        const isTransitioningMidnight =
          (nearestHour === 0 && direction === 1) ||
          (nearestHour === 24 && direction === -1) ||
          (nearestHour === 23 && direction === -1 && prevValue < 82800) ||
          (nearestHour === 0 && direction === 1 && prevValue > 82800);
        setSkipTransition(isTransitioningMidnight);

        // Convert back to seconds
        let targetValue = nearestHour * 3600;

        // Immediately transition to 1 AM when going from 24 to 0
        if (nearestHour === 0 && direction === 1) {
          targetValue = 3600; // 1 AM
        }
        // Immediately transition to 11 PM when going from 0 to 24
        else if (nearestHour === 24 && direction === -1) {
          targetValue = 82800; // 11 PM (23:00)
        }

        // Don't animate if we're already at the limit
        if (targetValue === prevValue) {
          return prevValue;
        }

        // Animate the transition
        const startValue = prevValue;
        const duration = 300; // 300ms transition
        const startTime = performance.now();

        const animate = (currentTime) => {
          const elapsedTime = currentTime - startTime;
          const progress = Math.min(elapsedTime / duration, 1);

          // Use easeInOutCubic for smooth acceleration and deceleration
          const easeProgress =
            progress < 0.5
              ? 4 * progress * progress * progress
              : 1 - Math.pow(-2 * progress + 2, 3) / 2;

          const newValue = Math.round(
            startValue + (targetValue - startValue) * easeProgress
          );
          setSliderValue(newValue);

          if (progress < 1) {
            requestAnimationFrame(animate);
          } else {
            setSkipTransition(false);
            if (isTransitioningMidnight) {
              setNoTransition(true);
              setTimeout(() => setNoTransition(false), 50); // Remove class after a short delay
            }
          }
        };

        if (isTransitioningMidnight) {
          return targetValue;
        } else {
          requestAnimationFrame(animate);
          return prevValue; // Return the previous value to avoid immediate jump
        }
      });

      setIsRealTimeActive(false);
    }
  }, []);

  // Add effect to listen for keyboard events
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const transitionDuration = 300; // 300ms transition for both sun and slider

  return (
    <div className={`${styles.cover} ${styles[`hour-${currentHour}`]}`}>
      <Ambiance currentHour={currentHour} noTransition={noTransition} />

      <div className={styles.contentWrap}>
        <AboutMe
          title={
            currentHour >= 3 && currentHour < 5
              ? "Not even the sun's awake."
              : currentHour >= 5 && currentHour < 12
              ? "Morning magic starts now!"
              : currentHour >= 12 && currentHour < 18
              ? "Good afternoon, lovely!"
              : currentHour >= 18 && currentHour < 22
              ? "Evening vibes are here!"
              : currentHour >= 22 && currentHour < 23
              ? "Good night, starlight!"
              : "Go to bed, night owl!"
          }
          currentHour={currentHour} // Pass currentHour as a prop
        />

        <div className={styles.controls}>
          <div className={styles.slider}>
            <div className={styles.time}>
              {formatTimeFromSeconds(sliderValue)}
            </div>

            <input
              type="range"
              min="0"
              max="86400"
              value={sliderValue}
              onChange={(e) => {
                setSliderValue(Number(e.target.value)); // Allow manual control
                setIsRealTimeActive(false); // Disable real-time updates on slider use
              }}
              onTouchStart={(e) => {
                e.target.focus();
              }}
              onTouchMove={(e) => {
                const touch = e.touches[0];
                const slider = e.target;
                const rect = slider.getBoundingClientRect();
                const position = (touch.clientX - rect.left) / rect.width;
                const newValue = Math.round(position * 86400);
                setSliderValue(Math.max(0, Math.min(86400, newValue)));
                setIsRealTimeActive(false);
              }}
              style={{
                transition: `all ${transitionDuration}ms ease-out`,
                WebkitAppearance: "none",
                appearance: "none",
                width: "100%",
                height: "44px", // Increased height for better touch target
                background: "transparent",
                outline: "none",
              }}
            />

            <div className={styles.sliderText}>
              <div className={styles.buttonContainer}>
                {!isRealTimeActive && (
                  <button onClick={revertToCurrentTime}>
                    Reset to Current Time
                  </button>
                )}
              </div>

              <div className={styles.helperText}>
                <div className={styles.text}>
                  Tip: Use left and right arrow keys to adjust time
                </div>
                <button
                  className={styles.keyboardLeft}
                  onClick={() => handleKeyDown({ key: "ArrowLeft" })}
                  aria-label="Decrease time by 1 hour"
                >
                  <svg width="100%" height="100%" viewBox="0 0 40 40">
                    <g transform="matrix(1,0,0,1,-32.0625,-29.95)">
                      <path d="M57.6,36.1L57.6,63.8L42.4,50L57.6,36.1M57.6,32.1C56.7,32.1 55.7,32.4 54.9,33.1L39.7,47C38,48.6 38,51.3 39.7,52.9L55,66.8C55.8,67.5 56.7,67.8 57.7,67.8C59.8,67.8 61.7,66.2 61.7,63.8L61.7,36.1C61.6,33.7 59.7,32.1 57.6,32.1Z" />
                    </g>
                  </svg>
                </button>
                <button
                  className={styles.keyboardRight}
                  onClick={() => handleKeyDown({ key: "ArrowRight" })}
                  aria-label="Increase time by 1 hour"
                >
                  <svg width="100%" height="100%" viewBox="0 0 40 40">
                    <g transform="matrix(1,0,0,1,-28.4,-30.2)">
                      <path d="M45.1,33.2C44.3,32.5 43.4,32.2 42.4,32.2C40.3,32.2 38.4,33.8 38.4,36.2L38.4,63.9C38.4,66.3 40.4,67.9 42.4,67.9C43.3,67.9 44.3,67.6 45.1,66.9L60.3,53C62,51.4 62,48.7 60.3,47.1L45.1,33.2ZM42.4,63.9L42.4,36.2L57.6,50L42.4,63.9Z" />
                    </g>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles.sunOrbit} ${styles[`hour-${currentHour}`]}`}>
        {/* Sun container that rotates around the circle */}
        <div
          className={styles.sunWrapper}
          style={{
            transform: `rotate(${calculateRotation(
              sliderValue
            )}deg) translateY(-50%)`,
            transition: skipTransition
              ? "none"
              : `transform, opacity ${transitionDuration}ms ease-out`,
          }}
        >
          <div className={styles.sun} />
        </div>

        {/* <div className={styles.horizon} /> */}
      </div>
    </div>
  );
};

export default SunPositionElement;
