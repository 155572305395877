import React from "react";
import styles from "./about.module.css";

const AboutMe = ({ title, currentHour }) => {
  return (
    <div className={`${styles.about} ${styles[`hour-${currentHour}`]}`}>
      <h1 className={styles.header}>{title}</h1>
      <div className={styles.tagline}>
        I'm Janice, part designer, part engineer, with 13+ years of experience
        and fully obsessed with making things better.
        <div className={styles.connectLink}>
          <span>
            <a href="mailto:hello@jpolicinski.com?subject=Let's%20Connect!">
              Let's Connect!
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
